.tabbedCarouselWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  width: 100%;
  max-width: 1600px;
  isolation: isolate;
  background-color: var(--background-color);
  padding: var(--ds-spacing-md) var(--ds-spacing-xs);
  padding-right: 0;

  &.hasTitle {
    grid-template-rows: repeat(3, auto);

    & [data-test='tabs-list'] {
      margin-top: 0;
      margin-bottom: 0;
    }

    & [data-test='tabs']:nth-child(2) {
      @media (--ds-breakpoint-sm) {
        grid-row: 3 / 3;
        grid-column: 1 / 2;
        z-index: 1;
        width: 100%;
        height: min-content;
      }
    }
  }

  @media (--ds-breakpoint-sm) {
    /* the second column's size is fixed to the width of the carousel nav buttons, to prevent tab text overflowing */
    grid-template-columns: 1fr 115px;
    grid-template-rows: repeat(2, auto);
    padding: var(--ds-spacing-lg);
  }

  /* TODO: remove these tab styles once SKRLL-183 is released and consumed in octan, as it will have the same effect */

  &.light {
    & button[role='tab'] {
      color: var(--ds-color-transparent-black-900);

      &:hover,
      &[aria-selected='true'] {
        color: var(--ds-color-action-static-dark-enabled);
        border-bottom: var(--ds-size-4xs) solid
          var(--ds-color-action-static-dark-enabled);
      }
    }
  }

  &.dark {
    & button[role='tab'] {
      color: var(--ds-color-transparent-white-900);

      &:hover,
      &[aria-selected='true'] {
        color: var(--ds-color-action-static-light-enabled);
        border-bottom: var(--ds-size-4xs) solid
          var(--ds-color-action-static-light-enabled);
      }
    }
  }
}

.title {
  grid-row: 1 / 1;
  color: var(--title-color);
  margin: 0;
  padding-bottom: var(--ds-spacing-xs);
  padding-left: var(--ds-spacing-3xs);

  &.center {
    text-align: center;
  }
}

.tabbedCarouselWrapper [data-test='tabs']:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 2;
  z-index: 1;
  width: 100%;
  height: min-content;
}

.carouselWrapper {
  display: contents;
}

.tabbedCarousel {
  grid-column: 1 / 1;
  grid-row: 1 / 3;
  width: 100%;
  display: none;
  opacity: 0;
  overflow: hidden;
  padding-top: var(--ds-spacing-xl);
  padding-left: 0;
  padding-bottom: 0;

  &.hasTitle {
    grid-row: 3 / 4;
    padding-top: 0;
  }

  &.active {
    display: block;
    opacity: 1;

    &.hasFiveProductsOrFewer {
      /* in cases where one carousel has five or fewer products we hide the navigation arrows. We add the padding below to create the space that the arrows and scrollbar take up to ensure a smooth transition between carousels */
      @media (--ds-breakpoint-lg) {
        padding-top: calc(
          var(--ds-spacing-lg) + var(--ds-spacing-2xs) + var(--ds-spacing-5xs)
        );
        padding-bottom: var(--ds-spacing-4xs);
      }
    }
  }

  @media (--ds-breakpoint-sm) {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding-top: 0;
  }
}
